import React, { useState, useEffect, useRef } from 'react'
import "./Lcix.scss"
import walmartService from "./walmartService"
import { marketplace, currencyMap } from "./wmConstants"
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import moment from "moment"
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button'
import { Ripple } from "primereact/ripple";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Panel } from 'primereact/panel'
import { MultiSelect } from 'primereact/multiselect'
import { Dialog } from 'primereact/dialog'
import OrderLineDetails from './orderLineDetails'
import { plazaServices } from '../tollPlaza/plazaReportService'
import { useLocation } from 'react-router-dom';

const Orders = () => {
    const [ordersData, setOrdersData] = useState([])
    const [selectedMarketplace, setSelectedMarketPlace] = useState({ name: "Walmart US", code: "walmart_us" })
    const [search, setSearch] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [expandedRowData, setExpandedRowData] = useState({})
    const [first, setFirst] = useState(0)
    const [orderStatus, setOrderStatus] = useState([])
    const [page, setPage] = useState(0)
    const [rowData, setRowData] = useState({})
    const [expandedRows, setExpandedRows] = useState(null)
    const [dateRangeOrdersInfo, setDateRangeOrdersInfo] = useState(null)
    const prevPage = useRef(null)
    const serviceDetails = JSON.parse(localStorage.getItem("service"))
    const startDate = moment().subtract(15, "d").format("MM/DD/YYYY");
    const endDate = moment().subtract(1, "d").format("MM/DD/YYYY");
    const [dateRange, setDateRange] = useState([new Date(startDate), new Date(endDate)]);
    const [orderItemDetails, setOrderItemDetail] = useState(null);
    const serviceId = localStorage.getItem("serviceId")
    const isInitalLoad = useRef(false)
    const location = useLocation();
    let state = location.state;
    if (state) state = [{ name: state.message, code: state.message }]
    else state = []
    const [selectedStatus, setSelectedStatus] = useState(state)

    useEffect(() => {
        let paylaod = {
            projection: { "_id": 0 }
        }
        walmartService.orderStatusMaster(serviceId, paylaod).then((res) => {
            res.length && setOrderStatus(res[0]?.orderStatus ?? [])
        })
    }, [])

    useEffect(() => {
        if (dateRange) {
            if (dateRange[1] !== null) {
                let payload = formPayload(0, 20, { searchKey: search, selectStatus: selectedStatus })
                initReport(payload, true)
                setFirst(0)
                setPage(0)
                prevPage.current = 0
            }
        }
    }, [dateRange])
    useEffect(() => {
        let timeoutid;
        if (isInitalLoad.current) {
            timeoutid = setTimeout(() => {
                let paylaod = formPayload(0, 20, { searchKey: search, selectStatus: selectedStatus })
                initReport(paylaod, true)
                setFirst(0)
                setPage(0)
            }, 1000)
        }
        isInitalLoad.current = true
        return () => clearTimeout(timeoutid)
    }, [search, JSON.stringify(selectedStatus)])
    const initReport = async (paylaod, toreset = false) => {
        return walmartService.getOrdersData(serviceId, paylaod).then(res => {
            let data = toreset ? res : [...ordersData, ...res]
            setOrdersData(data)
            setExpandedRows(null)
        })
    }
    useEffect(() => {
        const tempPayload = (skip = 0, limit = 6, filter) => {
            let matchObj = {
                ...(search && {
                    $or: [
                        { purchaseOrderId: { $regex: filter.searchKey, $options: "i" } },
                        { customerOrderId: { $regex: filter.searchKey, $options: "i" } }
                    ]
                }),
                ...(filter.selectStatus.length && {
                    lastStatus: { "$in": filter.selectStatus.map((item) => item.code) }
                })
            }
            if (dateRange.length === 2) {
                let fromDate = moment(dateRange[0]).startOf("day").valueOf()
                let toDate = moment(dateRange[1]).endOf("day").valueOf()
                matchObj["orderDate"] = { $gte: fromDate, $lte: toDate }
            }
            let newDataPayload = {
                operation: "aggregate",
                aggregate: [
                    {
                        "$match": matchObj
                    },
                    {
                        "$group": {
                            _id: null,
                            "totalQuantity": { "$sum": "$qty" },
                            "amount": { "$sum": "$charge.amount" },
                        }
                    }
                ]
            }
            return newDataPayload
        }
        let paylaod = tempPayload(0, 20, { searchKey: search, selectStatus: selectedStatus })
        Promise.all([walmartService.getOrdersData(serviceId, paylaod)]).then((res) => {
            setDateRangeOrdersInfo(res[0])
        })

    }, [dateRange, selectedStatus, search])
    const template = (options) => {
        const className = `${options.className} justify-content-between`;
        const titleClassName = `${options.titleClassName} pl-1`;
        const toggleIcon = options.collapsed ? "pi pi-chevron-down" : "pi pi-chevron-up";
        return (
            <div className={className}>
                <span className={titleClassName}>
                    <i className="pi-pw pi pi-filter"></i> Filter
                </span>
                {/* <Message style={{ background: "none" }} text="Search Status, Date, MarketPlace" /> */}
                <button className={options.togglerClassName} onClick={options.onTogglerClick}>
                    <span className={toggleIcon}></span>
                    <Ripple />
                </button>
            </div>
        );
    };
    const dateTemplate = (date) => {
        //moment(arr[i].dt).format('MMM Do YYYY')
        if (!date) return "--"
        if (serviceDetails?.region?.includes("us")) {
            return moment.utc(date).format("MMM DD, YYYY HH:mm");
        } else {
            return moment.utc(date).format("Do MMM, YYYY HH:mm");
        }
    };
    const onReset = () => {
        if (!selectedStatus.length && !search) return
        setOrdersData([])
        setSelectedStatus([])
        setSearch("")
        setExpandedRows(null)
        setFirst(0)
        setPage(0)
        prevPage.current = 0
    }
    const ONOrderClick = async (row) => {
        const payload = {
            filter: {
                "purchaseOrderId": row.data.purchaseOrderId
            },
            projection: {
                _id: 0,
                productName: 1,
                orderDate: 1,
                purchaseOrderId: 1,
                shippingInfo: 1,
                statusLog: 1,
                marketPlaceStatusLog: 1,
                "chargeBreakup.tax": 1,
                "indiaShipment.name": 1,
                "charge.amount": 1,
                parcelTrackingInfo: 1,
                indiaShipment: 1
            }
        }
        let res = await plazaServices.general(serviceId, payload, "nq-order-items")
        setOrderItemDetail(res[0])
        setRowData(row?.data)
        setShowModal(true)
    }
    const newPage = (e) => {
        setFirst(e.first)
        setPage(e.page)
        if (e.page > prevPage.current) {
            prevPage.current = e.page
            let payload = formPayload(ordersData.length, 20, { searchKey: search, selectStatus: selectedStatus })
            initReport(payload)
        }
    }
    const formPayload = (skip = 0, limit = 6, filter) => {
        let fromDate = moment(dateRange[0]).startOf("day").valueOf()
        let toDate = moment(dateRange[1]).endOf("day").valueOf()
        let matchObj = {
            orderDate: { $gte: fromDate, $lte: toDate },
            ...(search && {
                $or: [
                    { purchaseOrderId: { $regex: filter.searchKey, $options: "i" } },
                    { customerOrderId: { $regex: filter.searchKey, $options: "i" } }
                ]
            }),
            ...(filter.selectStatus.length && {
                lastStatus: { "$in": filter.selectStatus.map((item) => item.code) }
            })
        }
        let newDataPayload = {
            operation: "aggregate",
            aggregate: [
                {
                    "$match": matchObj
                },
                {
                    "$group": {
                        "_id": "$customerOrderId",
                        "totalQuantity": { "$sum": "$qty" },
                        "cancelledQuantity": { "$sum": { "$cond": [{ "$eq": ["$marketplaceStatus", "Cancelled"] }, "$qty", 0] } },
                        "orderDate": { "$first": "$orderDate" },
                        "listingPartner": { "$first": "$listingPartner" },
                        "currency": { "$first": "$charge.currency" },
                        "amount": { "$sum": "$charge.amount" },
                        "lastStatus": { "$addToSet": "$lastStatus" }
                    }
                },
                { $skip: skip }, { $limit: limit }, { $sort: { orderDate: -1 } }
            ]
        }
        return newDataPayload
    }
    const rowExpansionTemplate = (row) => {
        return (
            <div className='col-12'>
                <h5>Details of {row._id}</h5>
                <DataTable value={expandedRowData[row._id] ?? []} paginator={true} selectionMode='single' onRowClick={(e) => ONOrderClick(e)} rows={10} responsiveLayout="scroll" >
                    <Column header="Order Date" headerClassName="bg-blue-400 text-white white-space-nowrap" body={(item) => dateTemplate(item.orderDate)} bodyClassName=""></Column>
                    <Column header="Purchase Order ID" headerClassName="bg-blue-400 text-white white-space-nowrap" field="purchaseOrderId" ></Column>
                    <Column header="Isbn" headerClassName="bg-blue-400 text-white white-space-nowrap" field="sku" bodyClassName=""></Column>
                    <Column header="Quantity" headerClassName="bg-blue-400 text-white white-space-nowrap" field="orderQuantity"></Column>
                    <Column header="Amount" headerClassName="bg-blue-400 text-white white-space-nowrap" field="amount" body={amountTemplate}></Column>
                    <Column header="Print Partner" headerClassName="bg-blue-400 text-white white-space-nowrap" field="printPartner"></Column>
                    <Column header="Last Status" headerClassName="bg-blue-400 text-white white-space-nowrap" field="lastStatus"></Column>
                    <Column header="Last Status Update Date" headerClassName="bg-blue-400 text-white white-space-nowrap" body={(item) => dateTemplate(item.lastStatusUpdatedDate)}></Column>
                </DataTable>
            </div>
        )
    }
    const onRowExpandClick = (row) => {
        let paylaod = {
            filter: {
                customerOrderId: row.data._id,
                orderDate: row.data.orderDate
            },
            projection: {
                "_id": 0,
                "amount": "$charge.amount",
                "currency": "$charge.currency",
                "purchaseOrderId": 1,
                "sku": 1,
                "printPartner": 1,
                "reproPrintStatus": 1,
                "lastStatusUpdatedDate": "$lastStatusUpdatedAt",
                "lastStatus": 1,
                "orderPrintDate": 1,
                "orderQuantity": "$qty",
                orderDate: 1,
            }
        }
        walmartService.getOrderLineInfo(serviceId, paylaod).then(res => {
            setExpandedRowData({ ...expandedRowData, [row.data._id]: res })
        })
    }
    const amountTemplate = (row) => {
        let temp = Number.parseFloat(row.amount).toFixed(2)
        return currencyMap[row.currency] + " " + temp
    }
    return (
        <>
            <h4 className="text-left w-full mb-2 pl-2 " style={{ color: "grey" }}>Orders Dashboard</h4>
            <div className='grid col-12 justify-content-between align-items-center'>
                <div className="col-12  md:col-4 mb-3">
                    <label className='text-base pl-2'>Select Date Range</label>
                    <Calendar maxDate={new Date(moment().format("YYYY-MM-DD"))} id="range" value={dateRange} onChange={(e) => setDateRange(e.value)} selectionMode="range" showIcon readOnlyInput className="bg-white w-full mt-1 p-calendar flex align-items-center" style={{ border: '2px solid #0C6291', borderRadius: "3rem" }} />
                </div>
                <div className='col-12 lg:col-6 grid justify-content-end ml-2 '>
                    <div className="col-12  md:col-4 sm:col-6 xs:col-6 mb-3 lg:mr-3 lg:mb:0" style={{ padding: "0" }}>
                        <div className="displayCard color2-bg flex justify-content-center" >
                            <div className="flex justify-content-between">
                            </div>
                            <div className='flex flex-wrap justify-content-center '>
                                <div className='font-medium text-2xl flex flex-column justify-content-center align-items-center color2-bg '>
                                    <p className=' text-2xl color2-bg col-12 p-0' style={{margin:"0"}}>Order Qty</p>
                                    <p className='font-bold m-0 text-4xl'> {dateRangeOrdersInfo && dateRangeOrdersInfo[0] ? dateRangeOrdersInfo[0].totalQuantity : 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12  md:col-4 sm:col-6 xs:col-6 " style={{ padding: "0" }}>
                        <div className="displayCard color1-bg flex justify-content-center" >
                            <div className='flex flex-wrap justify-content-center '>
                                <div className='font-medium text-2xl flex flex-column justify-content-center align-items-center color1-bg '>
                                    <p className=' text-2xl color1-bg col-12 p-0' style={{margin:"0"}}>Value</p>
                                    <p className='text-4xl font-bold' style={{fontWeight:"bold"}}>  $ {dateRangeOrdersInfo && dateRangeOrdersInfo[0] ? dateRangeOrdersInfo[0].amount : 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <div className='displayCard radius12 p-1 bg-white'>
                    <Panel headerTemplate={template} toggleable collapsed={false} className='shadow-none bac'>
                        <div className="grid">
                            <div className="col-12 md:col-3 pt-2">
                                <InputText value={search} onChange={(e) => setSearch(e.target.value)} className="bg-white w-full" placeholder="Search" />
                            </div>
                            <div className="col-12 md:col-3">
                                <MultiSelect value={selectedStatus} options={orderStatus} onChange={(e) => setSelectedStatus(e.value)} display="chip" optionLabel="name" placeholder="Select Status" filter className="bg-white w-full" style={{ borderRadius: '20px', border: '2px solid #0C6291' }} />
                            </div>
                            <div className="col-12 md:col-3">
                                <Dropdown value={selectedMarketplace} disabled options={marketplace} /*onChange={(e) => handleFilter('marketplace', e.target.value)}*/ optionLabel="name" placeholder="MarketPlace" className="bg-white w-full" style={{ borderRadius: '20px', border: '2px solid #0C6291' }} />
                            </div>
                            <div className="col-12 md:col-2 mt-1">
                                <Button label="Reset" className=" p-button-danger" onClick={onReset} />
                            </div>
                        </div>
                    </Panel>
                </div>
                <div className="datatable-selection-demo mt-4">
                    <div className="card radius12">
                        <DataTable id="orderTable" value={ordersData} expandedRows={expandedRows} first={first} page={page} onPage={newPage} onRowToggle={(e) => setExpandedRows(e.data)} className='datatable-box bifarcation-box' responsiveLayout="scroll" rowExpansionTemplate={rowExpansionTemplate} onRowExpand={onRowExpandClick} rows={10} paginator emptyMessage="Orders Data not found">
                            <Column expander headerClassName="bg-blue-700" bodyClassName='text-color' style={{ width: '3em' }} />
                            <Column header="Order Date" headerClassName="bg-blue-700 text-white pl-2" body={(item) => dateTemplate(item.orderDate)} bodyClassName=""></Column>
                            <Column header="Customer Order ID" headerClassName="bg-blue-700 text-white pl-2" field="_id" bodyClassName=""></Column>
                            <Column header="Total Qty" headerClassName="bg-blue-700 text-white pl-2" field="totalQuantity"></Column>
                            <Column header="Cancelled Qty" headerClassName="bg-blue-700 text-white pl-2" field="cancelledQuantity"></Column>
                            <Column header="Amount" headerClassName="bg-blue-700 text-white pl-2" field="amount" body={amountTemplate}></Column>
                            <Column header="Listing Partner" headerClassName="bg-blue-700 text-white pl-2" field="listingPartner"></Column>
                            <Column header="Status" headerClassName="bg-blue-700 text-white pl-2" body={(row) => { return row.lastStatus && row.lastStatus.join(",") }}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
            <Dialog className='surface-ground w-9 ' style={{ backgroundColor: "red" }} header={<p>OrderLines</p>} visible={showModal} modal onHide={() => setShowModal(false)}>
                <div className="col-12">
                    <OrderLineDetails details={orderItemDetails} />
                </div>
            </Dialog>
        </>
    )
}
export default Orders