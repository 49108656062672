import React, { createContext, useRef, useState } from 'react'
import { getCountryCallingCode } from "react-phone-number-input";

const domainsMap = {
  "in.nextqore.com": "IN",
  "us.nextqore.com": "US",
  "dev.nextqore.io" : "IN",
  "localhost": "US"
}

export const StateContext = createContext(null)

const Context = ({ children }) => {
  const [countryCode, setCountryCode] = useState("")
  const [countryCodeVal, setCountryCodeVal] = useState("")

  const fetchUserCountryCode = async () => {
    try {
      const defCountryCode = localStorage.getItem("defaultCountryCode")
      if (defCountryCode) {
        let defaultCountryVal = "+" + getCountryCallingCode(defCountryCode)
        setCountryCodeVal(defaultCountryVal)
        setCountryCode(defCountryCode)
        return
      }

      const response = await fetch('https://ipinfo.io?token=89ef5eac0fc7e7');
      const data = await response.json();
      const countryCode = data.country;
      if (countryCode) {
        let defaultCountryVal = "+" + getCountryCallingCode(countryCode)
        setCountryCodeVal(defaultCountryVal)
        setCountryCode(countryCode);
        localStorage.setItem("defaultCountryCode", countryCode)
      }
      else {
        let hostname = window.location.hostname
        if (hostname) {
          let defaultCountryVal = "+" + getCountryCallingCode(domainsMap[hostname])
          setCountryCodeVal(defaultCountryVal)
          setCountryCode(domainsMap[hostname]);
          localStorage.setItem("defaultCountryCode", domainsMap[hostname])
        }
      }

    } catch (error) {
      let hostname = window.location.hostname
      if (hostname) {
        let defaultCountryVal = "+" + getCountryCallingCode(domainsMap[hostname])
        setCountryCodeVal(defaultCountryVal)
        setCountryCode(domainsMap[hostname]);
        localStorage.setItem("defaultCountryCode", domainsMap[hostname])
      }
      console.error('Error fetching user location:', error);
    }
  };

  return (
    <StateContext.Provider value={{ countryCode, countryCodeVal, setCountryCodeVal, fetchUserCountryCode }}>
      {children}
    </StateContext.Provider>
  )
}

export default Context