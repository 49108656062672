import React, { useEffect} from 'react'
import { Timeline } from 'primereact/timeline';
import "./Lcix.scss"
import moment from 'moment';
import { TabView, TabPanel } from 'primereact/tabview';

const OrderLineDetails = ({ details }) => {
    let flexTime=""
    let flexPortInfo = details.parcelTrackingInfo?  details.parcelTrackingInfo.map((item) => {
        return {
            status: `${item.status} (${item.message})`,
            date:moment.utc(item.ts).tz("America/New_York").format("MMM DD, YYYY HH:mm"),
            completed: true
        }
    }):[]
    let marketTimeLine = details.statusLog?  details.statusLog.map((item) => {
        return {
            status: item.status,
            date: moment.utc(item.dt).tz("America/New_York").format('MMM Do YYYY HH:mm'),
            completed: true
        }
    }):[]
    flexPortInfo=[...marketTimeLine,...flexPortInfo]  
    let date = new Date(details.orderDate);
    date = date.toLocaleDateString();
    useEffect(() => {

    }, [])
    const customizedMarker = (item) => {
        return (
            <span className="flex align-items-center justify-content-center text-white border-circle z-1 shadow-2" style={{ backgroundColor: item.completed ? "#26A541" : "#bdbdbd", width: "1.5rem", height: "1.5rem" }} >
                {item.completed && <i className='pi pi-check' style={{}}></i>}
            </span>
        );
    };
    const customizedContent = (item) => {
        return (
            <div className='p-0 '>
                <p className='m-0 mb-1 text-sm lg:font-semibold'>{item.status}</p>
                <p className=''>{item.date}</p>
            </div>
        );
    };

    const getTextContent = (text) => {
        text = text.replace(/&#[0-9]+;/g, '');
        text = text.replace(/[\(\)\/]/g, '').trim();
        return text;
    }
    return (
        <>
            <div className='mb-3'>
                <div className='card flex flex-wrap shadow-4'>
                    <div className='lg:col-3 md:col-2 col-12 md:border-right-1 border-300'>
                        <div className="">
                            <h5 className="">Delivery Address</h5>
                            <h6 className="m-0 mb-1">{details?.shippingInfo?.postalAddress?.name ?? 'N/A'}</h6>
                            <p className="line-height-3 mb-2 flex flex-wrap">
                                {details?.shippingInfo?.postalAddress?.address1 ?? 'N/A'},
                                {details?.shippingInfo?.postalAddress?.address2 ?? ''},
                                {details?.shippingInfo?.postalAddress?.city ?? ''},
                                {details?.shippingInfo?.postalAddress?.postalCode ?? ''}.
                                {details?.shippingInfo?.postalAddress?.state ?? ''},
                                {details?.shippingInfo?.postalAddress?.country ?? ''}
                            </p>
                            <div className="">
                                <span className="font-semibold">Phone number: </span>
                                <span>{details?.shippingInfo?.phone ?? 'N/A'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='lg:col-3 md:col-2 col-12 md:border-right-1 md:border-top-none border-top-1 border-300'>
                        <div className="md:pl-3 line-height-3">
                            <h5 className="">Order Details</h5>
                            <div className="">
                                <span className="font-semibold">Product Name: </span>
                                <span>{getTextContent(details?.productName ?? 'N/A')}</span>
                            </div>
                            <div className="">
                                <span className="font-semibold">Order ID: </span>
                                <span>{details?.purchaseOrderId ?? 'N/A'}</span>
                            </div>
                            <div className="">
                                <span className="font-semibold">Order Date: </span>
                                <span>{date ?? 'N/A'}</span>
                            </div>
                            <div className="">
                                <span className="font-semibold">Ship By: </span>
                                <span>{details?.indiaShipment?.name ?? 'N/A'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='lg:col-2 md:col-2 col-12 md:border-right-1 md:border-top-none border-top-1 border-300'>
                        <div className="md:pl-3 line-height-3">
                            <h5 className="">Price Distribution</h5>
                            <div className="">
                                <span className="font-semibold">Total: </span>
                                <span>{details?.charge?.amount ?? 'N/A'}</span>
                            </div>
                            <div className="">
                                <span className="font-semibold">Shipping Fee: </span>
                                <span>{details?.chargeBreakup?.[0]?.shippingFee?.amount ?? 'N/A'}</span>
                            </div>
                            <div className="">
                                <span className="font-semibold">Taxes: </span>
                                <span>{details?.chargeBreakup?.[0]?.tax?.taxAmount?.amount ?? 'N/A'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='lg:col-3 md:col-1 col-12 border-top-1 md:border-none border-300'>
                        <div className="md:pl-3 line-height-3">
                            <h5 className="">Aramex</h5>
                            <div className="">
                                <span className="font-semibold">Tracking Id: </span>
                                <span>{details?.indiaShipment?.shipmentId ?? 'N/A'}</span>
                            </div>
                            <div className="">
                                <span className="font-semibold">Tracking Url: </span>
                                <span><a style={{wordWrap:"break-word"}} href={details?.indiaShipment?.trackUrl ?? 'N/A'}>{details?.indiaShipment?.trackUrl ?? 'N/A'}</a></span>
                            </div>
                            <div className="">
                                <span className="font-semibold">Time: </span>
                                <span>{flexTime}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-12'>
                <div className='card grid shadow-4'>
                    <div className="col-12 lg:col-12">
                        <TabView>
                            { <TabPanel header="Time Line of events">
                                <div className='overflow-auto h-18rem condition-true'>
                                    <Timeline
                                        className='overflow-auto custom-timeline lcix_status'
                                        value={flexPortInfo}
                                        layout="vertical"
                                        align="alternate"
                                        content={customizedContent}
                                        marker={customizedMarker}
                                        opposite={<span>&nbsp;</span>}
                                    />
                                </div>
                            </TabPanel>}
                        </TabView>
                    </div>
                    <div className="col-12 lg:col-12">
                    </div>
                </div>
            </div>
        </>

    )
}

export default OrderLineDetails